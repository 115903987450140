export const JobEndTimes = {
	8: 12,
	12: 16,
	16: 22,
};

export const Categories = [
	'Managing medications',
	'Prescription pick up/drop off',
	// 'Telehealth support',
	'Groceries',
	'Meal preparation',
	'Laundry',
	'Companionship',
	'Mobility help',
	'Insurance navigation',
	'Medical supply organization',
	'Light homemaking'
];

export const USStateCodes = {
	AL: 'AL',
	AK: 'AK',
	AZ: 'AZ',
	AR: 'AR',
	CA: 'CA',
	CO: 'CO',
	CT: 'CT',
	DE: 'DE',
	DC: 'DC',
	FL: 'FL',
	GA: 'GA',
	HI: 'HI',
	ID: 'ID',
	IL: 'IL',
	IN: 'IN',
	IA: 'IA',
	KS: 'KS',
	KY: 'KY',
	LA: 'LA',
	ME: 'ME',
	MD: 'MD',
	MA: 'MA',
	MI: 'MI',
	MN: 'MN',
	MS: 'MS',
	MO: 'MO',
	MT: 'MT',
	NE: 'NE',
	NV: 'NV',
	NH: 'NH',
	NJ: 'NJ',
	NM: 'NM',
	NY: 'NY',
	NC: 'NC',
	ND: 'ND',
	OH: 'OH',
	OK: 'OK',
	OR: 'OR',
	PA: 'PA',
	RI: 'RI',
	SC: 'SC',
	SD: 'SD',
	TN: 'TN',
	TX: 'TX',
	UT: 'UT',
	VT: 'VT',
	VA: 'VA',
	WA: 'WA',
	WV: 'WV',
	WI: 'WI',
	WY: 'WY',
	PR: 'PR',
};


export const replacementFields = {
	addressLine1: "Street Address",
	addressLine2: "Address",
	city: "City",
	state: "State",
	zipCode: "Zip Code",
	// languages: "Primary language",
	"languages.primary": "Primary language",
	availabilities: "Schedule",
	rate: "Hourly rate",
	cost: "Category",
	paypal: "PayPal information",
	phone: "Phone number",
	avatar: "Profile Picture",
	stree_number: "Street number",
	route: "Street Address",
	locality: "City",
	administrative_area_level_1: "State",
	postal_code: "Zip Code",
	country: "Country",
	isAddressVerified: "Address Verification",
	isPhoneVerified: "Phone Verification",
}

export const cards = [
	{
		name: 'Dianna',
		avatar: '/img/senior1.png',
		review: 'Marita is very knowledgeable, personable and professional.  We would recommend her as a caretaker.',
	},
	{
		name: 'Mary',
		avatar: '/img/senior2.png',
		review: 'Very pleasant experience',
	}
]