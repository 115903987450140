import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const EmailUnsubscribe = () => {
    const [response, setResponse] = useState(null)
    const { token } = useParams();

    const EmailUnsubscribe = async (e, isUnsubscribe) => {
        e.preventDefault();
        const subScribeResponse = await axios.patch(`/auth/unsubscribe/${token}`, {
            isUnsubscribe
        });
        setResponse(subScribeResponse.data.message);
    }

    useEffect(() => {
        if (response) {
            const timer = setTimeout(() => setResponse(null), 4000);
            return () => clearTimeout(timer);
        }
    }, [response]);

    return (
        <div className="email-unsubscribe page">
            <div className="unsubscribe-container">
                <h1>Unsubscribe from Work Notifications</h1>
                We value your collaboration with us. If you no longer wish to receive work-related email notifications, please confirm your choice below.
                <form >
                    <button onClick={(e) => EmailUnsubscribe(e, false)} className="unsubscribe-button">
                        Unsubscribe
                    </button>
                </form>
                <button onClick={(e) => EmailUnsubscribe(e, true)} className="go-back">
                    Nevermind, take me back
                </button>

                {response && (
                    <div className={"popup-overlay"}>
                        <div className="popup-message">
                            {response}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default EmailUnsubscribe